import CustomModal from '@/components/atoms/CustomModal'
import EditUserForm from '@/components/molecules/Form/EditUserForm'
import { errors } from '@/constant/errors'
import { userApi } from '@/ghgApi'
import ReactHookForm from '@/providers/ReactHookForm'
import { TAllFormValues } from '@/react-hook-form/types'
import { schemaEditUser } from '@/react-hook-form/validations'
import { AppDispatch } from '@/store'
import { companyStore, createUser, deleteUser, getUsersByOrganizationId } from '@/store/slices/companiesSlice'
import { setMessage } from '@/store/slices/messageSlice'
import { AxiosError } from 'axios'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TModalCompany } from './ModalCreateCompany'
import SelectSitesModal from './SelectSitesModal'

export default function ModalEditUser(props: TModalCompany) {
    const dispatch = useDispatch<AppDispatch>()
    const { listUsers, organizationId, usersTotal, reUpdateModal } = useSelector(companyStore)
    // state pagination
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    // state form
    const [itemEditing, setItemEditing] = useState<number | null>(null)
    const [addMode, setAddMode] = useState(false)
    const [error, setError] = useState('')
    const [selectedUserId, setSelectedUserId] = useState('')
    const [showSelectSitesModal, setShowSelectSitesModal] = useState(false)

    useEffect(() => {
        if (organizationId || organizationId === 0)
            dispatch(
                getUsersByOrganizationId({
                    id: organizationId,
                    options: {
                        search: searchTerm ? searchTerm : undefined,
                        role: undefined,
                        offset: page * rowsPerPage,
                        limit: rowsPerPage,
                    },
                }),
            )
    }, [searchTerm, page, rowsPerPage, reUpdateModal])

    const handleClose = () => {
        props.setOpen(false)
        setSearchTerm('')
        setPage(0)
        setRowsPerPage(10)
        setSelectedUserId('')
    }

    const handleCloseSelectSitesModal = (isAllClose: boolean) => {
        setShowSelectSitesModal(false)
        if (isAllClose) {
            handleClose()
        }
    }

    const handleShowSiteSelectModal = async (userId: string) => {
        setShowSelectSitesModal(true)
        setSelectedUserId(userId)
    }

    const handleSubmit = async ({ users }: TAllFormValues['EditUsers']) => {
        const user = users.find((s) => s.isChange)
        if (user && (organizationId || organizationId === 0)) {
            if (!user?.isNewUser && user.userId) {
                try {
                    await userApi.updateUser(user.userId, { name: user.name, email: user.email, roleId: user.roleId })
                    dispatch(setMessage({ message: 'ユーザーの更新に成功しました！', type: 'success' }))
                } catch (err) {
                    dispatch(
                        setMessage({
                            message: errors.ERROR_OCCURRED,
                            type: 'error',
                        }),
                    )
                    return
                }
            } else {
                const res = await dispatch(
                    createUser({
                        id: organizationId,
                        user: { name: user?.name, roleId: user.roleId, email: user.email },
                    }),
                )
                const error = res.payload as AxiosError
                if (error?.response) {
                    dispatch(
                        setMessage({
                            message: error.response.data?.errors[0].message || errors.ERROR_OCCURRED,
                            type: 'error',
                        }),
                    )
                    return
                }
                dispatch(setMessage({ message: 'ユーザーの作成に成功しました！', type: 'success' }))
                setError('')
                setAddMode(false)
                setItemEditing(null)
            }
        }
    }

    const handleDeleteUser = async (userId: string) => {
        const res = await dispatch(deleteUser(userId))
        const error = res.payload as AxiosError
        if (error.response) {
            dispatch(
                setMessage({
                    message: error.response.data?.errors[0].message || errors.ERROR_OCCURRED,
                    type: 'error',
                }),
            )
        } else {
            dispatch(setMessage({ message: 'ユーザーの削除に成功しました！', type: 'success' }))
        }
    }

    const defaultValues = useMemo(
        () => ({
            users: listUsers.map((user) => ({ ...user, userId: user.id, isChange: false })),
        }),
        [listUsers],
    )

    return (
        <>
            <CustomModal width={1000} title="ユーザー一覧" open={props.open} onClose={handleClose}>
                <>
                    <ReactHookForm validateSchema={schemaEditUser} defaultValues={defaultValues}>
                        <EditUserForm
                            onClose={handleClose}
                            onSubmit={handleSubmit}
                            onDelete={handleDeleteUser}
                            onShowSiteSelectModal={handleShowSiteSelectModal}
                            pagination={{ page, setPage, rowsPerPage, setRowsPerPage, total: usersTotal }}
                            defaultValues={defaultValues}
                            state={{ addMode, setAddMode, itemEditing, setItemEditing, error }}
                        />
                    </ReactHookForm>
                </>
            </CustomModal>
            <SelectSitesModal userId={selectedUserId} open={showSelectSitesModal} onClose={handleCloseSelectSitesModal} />
        </>
        
    )
}
