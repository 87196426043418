import { Site, SiteNode } from "@/openapi"

export const flattenNodes = (nodes: SiteNode[]): SiteNode[] => {
    return nodes.flatMap(node => [node, ...flattenNodes(node.children || [])])
}

export const getHierarchyPath = (sites: Site[], siteId: number) => {
    const site = sites.find(s => s.id === siteId);
    if (!site) return '';

    const path = [site.name];
    let currentSite = site;

    while (currentSite.parentSiteId) {
        const parentSite = sites.find(s => s.id === currentSite.parentSiteId);
        if (!parentSite) break;
        path.unshift(parentSite.name);
        currentSite = parentSite;
    }
    
    path.reverse()
    return path.join(' < ');
}

export const getHierarchyLevel = (allSites: Site[], siteId: number) => {
    const parentSites: Site[] = []
    addParentSites(allSites, parentSites, siteId)
    return parentSites.length
}

const addParentSites = (allSites: Site[], parentSites: Site[], siteId: number) => {
    const site = allSites.find((s) => { return s.id == siteId })
    if (!site) {
        return
    }
    parentSites.push(site)
    if (!!site.parentSiteId) {
        addParentSites(allSites, parentSites, site.parentSiteId)
    }
}

export const getDescendantsSiteIds =(
    siteId: number,
    allSites: Site[]
) => {
    const childrenMap = createChildrenMap(allSites);
    return collectDescendants(siteId, childrenMap);
}

const createChildrenMap = (allSites: Site[]) => {
    return allSites.reduce((map, site) => {
        if (site.parentSiteId != null) {
            const children = map.get(site.parentSiteId) || new Set<number>();
            children.add(site.id);
            map.set(site.parentSiteId, children);
        }
        return map;
    }, new Map<number, Set<number>>());
}

const collectDescendants = (
    siteId: number,
    childrenMap: Map<number, Set<number>>,
    descendantIds: number[] = [],
    visited: Set<number> = new Set()
) => {
    if (visited.has(siteId)) {
        return descendantIds;
    }

    visited.add(siteId);
    descendantIds.push(siteId);

    const children = childrenMap.get(siteId) || new Set<number>();
    for (const childSiteId of children) {
        collectDescendants(childSiteId, childrenMap, descendantIds, visited);
    }

    return descendantIds;
}

export const getMaxDepth = (rootNode: SiteNode): number => {
    // 子ノードがない場合、階層数は1（自身のみ）
    if (!rootNode.children || rootNode.children.length === 0) {
        return 1;
    }
    // 再帰ケース: 各子ノードの最大階層数を取得し、その中で最大のものに1を足す
    const childDepths = rootNode.children.map(child => getMaxDepth(child));
    return 1 + Math.max(...childDepths);
}
