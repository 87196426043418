import ButtonRounded from '@/components/atoms/Button/ButtonRounded'
import InputField from '@/components/atoms/InputField'
import SelectField from '@/components/atoms/SelectField'
import { MONTH_OPTIONS, PLAN_OPTIONS, STATUS_OPTIONS } from '@/constant/options'
import { useTypedForm } from '@/hooks/useTypedForm'
import { TAllFormValues } from '@/react-hook-form/types'
import React from 'react'
import { Controller, SubmitHandler } from 'react-hook-form'

interface TEditCompanyForm {
    onClose: () => void
    onSubmit: SubmitHandler<TAllFormValues['EditCompany']>
}

export default function EditCompanyForm(props: TEditCompanyForm) {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useTypedForm('EditCompany')

    return (
        <form onSubmit={handleSubmit(props.onSubmit)} className="px-100">
            <div className="mt-40">
                <div className="mt-20">
                    <Controller
                        name="name"
                        render={({ field: { ref, ...rest } }) => {
                            return (
                                <InputField
                                    {...rest}
                                    size="small"
                                    id="name"
                                    label="企業名称"
                                    error={errors.name?.message ? true : false}
                                    helperText={errors.name?.message}
                                />
                            )
                        }}
                    />
                </div>
                {/* <div className="mt-20">
                    <Controller
                        render={({ field: { ref, ...rest } }) => {
                            return (
                                <SelectField
                                    {...rest}
                                    id="planId"
                                    label="プラン"
                                    size="small"
                                    menuItems={PLAN_OPTIONS}
                                />
                            )
                        }}
                        name="planId"
                        control={control}
                    />
                </div> */}
                <div className="mt-20">
                    <Controller
                        render={({ field: { ref, ...rest } }) => {
                            return (
                                <SelectField
                                    {...rest}
                                    id="startMonth"
                                    label="開始月"
                                    size="small"
                                    menuItems={MONTH_OPTIONS}
                                />
                            )
                        }}
                        name="startMonth"
                        control={control}
                    />
                </div>
                <div className="mt-20">
                    <Controller
                        render={({ field: { ref, ...rest } }) => {
                            return (
                                <SelectField
                                    {...rest}
                                    id="status"
                                    label="ステータス"
                                    size="small"
                                    menuItems={STATUS_OPTIONS}
                                />
                            )
                        }}
                        name="status"
                        control={control}
                    />
                </div>
            </div>
            <div className="mt-40 mb-10 flex-center">
                <ButtonRounded className="company-form-btn" types="outline" onClick={props.onClose}>
                    キャンセル
                </ButtonRounded>
                <ButtonRounded className="company-form-btn ml-20" type="submit" types="filled">
                    保存
                </ButtonRounded>
            </div>
        </form>
    )
}
