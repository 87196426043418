import Bubble from '@/components/atoms/Bubble'
import ForgotPasswordForm from '@/components/molecules/Form/forgotPasword'
import ResetPasswordForm from '@/components/molecules/Form/resetPasswrod'
import { errors } from '@/constant/errors'
import { validate } from '@/constant/validate'
import ReactHookForm from '@/providers/ReactHookForm'
import { TAllFormValues } from '@/react-hook-form/types'
import { schemaForgotPassword, schemaResetPassword } from '@/react-hook-form/validations'
import { routes } from '@/routes'
import { AppDispatch } from '@/store'
import { setMessage } from '@/store/slices/messageSlice'
import translate from '@/utils/translations'
import { Auth } from '@aws-amplify/auth'
import { navigate } from 'gatsby'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

function ForgotPassword() {
    const [showChangePasswordForm, setShowChangePasswordForm] = useState(false)
    const [userEmail, setUserEmail] = useState('')
    const dispatch = useDispatch<AppDispatch>()

    const handleForgotPasswordSubmit = async (value: TAllFormValues['ForgotPassword']) => {
        try {
            await Auth.forgotPassword(value.email)
            setUserEmail(value.email)
            setShowChangePasswordForm(true)
        } catch (err: any) {
            const error = translate(err?.message)
            dispatch(setMessage({ message: error || errors.ERROR_OCCURRED, type: 'error' }))
        }
    }

    const handleChangePasswordSubmit = async ({ password, code }: TAllFormValues['ResetPassword']) => {
        if (password === userEmail)
            return dispatch(setMessage({ message: validate.PASSWORD_SAME_AS_EMAIL, type: 'error' }))
        try {
            await Auth.forgotPasswordSubmit(userEmail, code, password)
            dispatch(setMessage({ message: 'パスワード変更は成功です!', type: 'success' }))
            navigate(routes.login)
        } catch (error: any) {
            const translation = translate(error?.message)
            dispatch(setMessage({ message: translation || errors.ERROR_OCCURRED, type: 'error' }))
        }
    }

    if (showChangePasswordForm) {
        return (
            <div className="flex-center flex-column">
                <div className="flex-center pa-60">
                    <img className="width-400" src="/assets/logo/logo_EV.png" alt="logo.png" />
                </div>
                <Bubble className="width-600 pa-30">
                    <ReactHookForm validateSchema={schemaResetPassword} defaultValues={{ password: '', code: '' }}>
                        <ResetPasswordForm onSubmit={handleChangePasswordSubmit} />
                    </ReactHookForm>
                </Bubble>
            </div>
        )
    }

    return (
        <div className="flex-center flex-column">
            <div className="flex-center pa-60">
                <img className="width-400" src="/assets/logo/logo_EV.png" alt="logo.png" />
            </div>
            <Bubble className="width-600 pa-30">
                <ReactHookForm validateSchema={schemaForgotPassword} defaultValues={{ email: '' }}>
                    <ForgotPasswordForm onSubmit={handleForgotPasswordSubmit} />
                </ReactHookForm>
            </Bubble>
        </div>
    )
}

export default ForgotPassword
