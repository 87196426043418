import { Button, ButtonProps } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles({
    disabled: {
        color: '#fff !important',
        backgroundColor: '#BABABA !important',
    },
})

function PrimaryButton({ children, className, variant = 'contained', classes, ...props }: ButtonProps) {
    const { disabled } = useStyles()
    return (
        <Button
            {...props}
            className={`primary-btn fw-300 ${className}`}
            variant={variant}
            classes={{ ...classes, disabled }}
        >
            {children}
        </Button>
    )
}

export default PrimaryButton
