import Auth from '@aws-amplify/auth'
// @ts-ignore
import {
    AuthAPIApi,
    Category,
    Configuration,
    EmissionFactor,
    EmissionFactorAPIApi,
    MonthlyOrganizationSummary,
    OrganizationAPIApi,
    OrganizationSummaryAPIApi,
    Scope,
    ScopesCategoriesAPIApi,
    Site,
    SiteActivityAPIApi,
    SiteAPIApi,
    SiteSubmissionAPIApi,
    SiteSummaryAPIApi,
    SiteNodeAPIApi,
    UserAPIApi,
    NotificationAPIApi,
    AntiWarmingApi,
    EnergySavingApi,
} from '@/openapi'
import { navigate } from 'gatsby'

// =================================================================================
// Open API Config
// =================================================================================
export const configureGhgApi = (baseUrl: string) => {
    const openApiConfig = new Configuration({
        basePath: baseUrl,
        accessToken: () =>
            Auth.currentSession()
                .then((session) => session.getAccessToken().getJwtToken())
                .catch((err) => {
                    console.warn(err)
                    navigate('/')
                    return ''
                }),
    })
    authApi = new AuthAPIApi(openApiConfig)
    emissionFactorApi = new EmissionFactorAPIApi(openApiConfig)
    organizationApi = new OrganizationAPIApi(openApiConfig)
    scopesCategoriesApi = new ScopesCategoriesAPIApi(openApiConfig)
    siteApi = new SiteAPIApi(openApiConfig)
    siteNodeApi = new SiteNodeAPIApi(openApiConfig)
    userApi = new UserAPIApi(openApiConfig)
    notificationApi = new NotificationAPIApi(openApiConfig)
    antiWarmingApi = new AntiWarmingApi(openApiConfig)
    energySavingApi = new EnergySavingApi(openApiConfig)
}

// =================================================================================
// APIs
// =================================================================================
export let emissionFactorApi: EmissionFactorAPIApi
export let organizationApi: OrganizationAPIApi
export let organizationSummaryApi: OrganizationSummaryAPIApi
export let scopesCategoriesApi: ScopesCategoriesAPIApi
export let siteApi: SiteAPIApi
export let siteActivityApi: SiteActivityAPIApi
export let siteSubmissionApi: SiteSubmissionAPIApi
export let siteSummaryApi: SiteSummaryAPIApi
export let siteNodeApi: SiteNodeAPIApi
export let authApi: AuthAPIApi
export let userApi: UserAPIApi
export let notificationApi: NotificationAPIApi
export let antiWarmingApi: AntiWarmingApi
export let energySavingApi: EnergySavingApi

// =================================================================================
// Types
// =================================================================================
export type Year = ReturnType<Date['getFullYear']>
export type Month = string

// Quick and dirty cause we can't extend enums in TS: https://github.com/microsoft/TypeScript/issues/17592
export enum InputStatus {
    DONE = 'done', // SubmissionStatus.Done
    IN_PROGRESS = 'in progress', // SubmissionStatus.InProgress
    NOT_NEEDED = 'not needed',
}

export namespace Summary {
    export interface HasMonth {
        month: Month
    }
    export interface ScopesSummary {
        scopes: Array<Summary.ScopeSummaryItem>
    }

    export interface Monthly {
        month: Month
        quantity: number
        scopes: Array<Summary.ScopeSummaryItem>
        sites?: Array<Summary.SiteSummaryItem>
    }

    export interface Annual {
        year: Year
        quantity: number
        scopes: Array<Summary.ScopeSummaryItem>
        months: Array<MonthlyOrganizationSummary>
        sites?: Array<Summary.SiteSummaryItem>
    }

    export type CategorySummaryItem = {
        id: EmissionFactor['id']
        name: EmissionFactor['name']
        quantity: number
        categoryEmissionFactorTableId: number
    }

    export type CategorySummary = {
        id: Category['id']
        name: string
        quantity: number
        highestEmissionFactors: Array<CategorySummaryItem>
        highestEmissionFactorTables: Array<CategorySummaryItem>
    }

    export type ScopeSummaryItem = {
        id: Scope['id']
        quantity: number
        categories: Array<CategorySummary>
    }

    export type SiteSummaryItem = {
        id: Site['id']
        name: Site['name']
        quantity: number
        scopes: Array<ScopeSummaryItem>
    }
}

// =================================================================================
//  API Helper Functions
// =================================================================================
export function appendQueryParams(queryParams: { [key: string]: any }, url: string): string {
    url += '?'
    for (const [key, value] of Object.entries(queryParams)) {
        url += `${key}=${value}&`
    }
    url = url.slice(0, -1)
    return url
}

export function makeMonth(year: number, month: number): Month {
    return `${year}-${String(month).padStart(2, '0')}`
}
