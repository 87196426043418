import ArrowExpandIcon from '@/components/atoms/Icon/ArrowExpandIcon'
import BusinessIcon from '@/components/atoms/Icon/BusinessIcon'
import DashboardIcon from '@/components/atoms/Icon/DashboardIcon'
import ExpandedIcon from '@/components/atoms/Icon/ExpandedIcon'
import NotificationsIcon from '@/components/atoms/Icon/NotificationsIcon'
import { routes } from '@/routes'
import customTheme from '@/theme'
import { Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { navigate } from 'gatsby'
import React from 'react'
import { TMenuItem } from '../Header'

interface TMenuBar {
    open: boolean
    expandedMenuId: number[]
    initialTabId: number[]
    drawerWidth: number
    handleDrawerOpen: () => void
    handleDrawerClose: () => void
    handleTabClick: (path?: string, id?: number) => void
}
type TMenuList = {
    id: number
    text: string
    icon: JSX.Element
    path?: string
    isActive?: boolean
    children?: (Required<Omit<TMenuItem, 'children' | 'icon'>> & { disabled?: boolean })[]
    disabled?: boolean
}
function MenuBar(props: TMenuBar) {
    const { open, expandedMenuId, initialTabId, drawerWidth, handleDrawerOpen, handleDrawerClose, handleTabClick } =
        props
    const path = typeof window !== 'undefined' ? window.location.pathname : ''

    const menuList: TMenuList[] = [
        { id: 1, text: '企業一覧', icon: <BusinessIcon />, path: routes.home, isActive: path === routes.home },
        {
            id: 2,
            text: '係数設定',
            icon: <DashboardIcon />,
            children: [
                {
                    id: 2.1,
                    text: '種別一覧',
                    path: routes.emissionFactor,
                    isActive: path.includes(routes.emissionFactor) && !path.includes(routes.emissionFactorId),
                },
                {
                    id: 2.2,
                    text: '係数マスタ',
                    path: routes.emissionFactorId,
                    isActive: path.includes(routes.emissionFactorId),
                },
            ],
        },
        // {
        //     id: 3,
        //     text: 'お知らせ管理',
        //     icon: <NotificationsIcon />,
        //     path: routes.notification,
        //     isActive: path.includes(routes.notification),
        // },
    ]

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            drawer: {
                width: drawerWidth,
                flexShrink: 0,
                whiteSpace: 'nowrap',
                background: customTheme.colors.lightGray,
            },
            drawerOpen: {
                width: drawerWidth,
                backgroundColor: customTheme.colors.lightGray,
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
            drawerClose: {
                backgroundColor: customTheme.colors.lightGray,
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                overflowX: 'hidden',
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            },
            toolbar: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: theme.spacing(0, 1),
                // necessary for content to be below app bar
                ...theme.mixins.toolbar,
            },
            expandBtn: {
                padding: 0,
                position: 'fixed',
                top: 100,
                left: 59,
                transition: 'left 0.225s cubic-bezier(0.4, 0, 0.6, 1) 0ms',
                '&:hover': {
                    backgroundColor: customTheme.colors.white,
                },
            },
            expandBtnActive: {
                left: 242,
            },
            slogan: {
                color: customTheme.colors.primaryN,
                fontSize: 14,
                textAlign: 'center',
                width: 0,
                overflow: 'hidden',
                transition: 'width 0.225s cubic-bezier(0.4, 0, 0.6, 1) 0ms',
            },
            sloganActive: {
                width: '100%',
            },
            menuItemWrapper: {
                margin: '5px 10px',
                minHeight: 0,
                maxHeight: 55,
                borderRadius: 8,
                overflow: 'hidden',
                transition: 'all 0.225s cubic-bezier(0.4, 0, 0.6, 1) 0ms',
            },
            menuItem: {
                height: 55,
                borderRadius: 8,
                position: 'relative',
            },
            menuItemText: {
                fontSize: 14,
                fontWeight: 600,
            },
            menuItemActive: {
                backgroundColor: `${customTheme.colors.primaryN} !important`,
                color: customTheme.colors.white,
                '& svg > path': {
                    fill: customTheme.colors.white,
                },
                '& svg > g > path': {
                    stroke: customTheme.colors.white,
                },
                '& svg > g > circle': {
                    fill: customTheme.colors.white,
                },
                '&:hover': {
                    backgroundColor: customTheme.colors.primaryN,
                },
            },
            menuItemExpanded: {
                maxHeight: 200,
                minHeight: 40,
                paddingBottom: 15,
            },
            backgroundActive: {
                backgroundColor: customTheme.colors.expandBackground,
                '&:hover': {
                    backgroundColor: customTheme.colors.expandBackground,
                },
            },
            itemChildWrapper: {
                margin: '5px 20px',
            },
            menuItemChild: {
                height: 32,
                width: '100%',
                backgroundColor: customTheme.colors.white,
            },
        }),
    )
    const classes = useStyles()

    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
        >
            <div className={classes.toolbar} style={{ height: 74 }}>
                <img
                    src="/assets/logo/logo_EV.png"
                    onClick={() => navigate(routes.home)}
                    alt="logo"
                    width={'90%'}
                    style={{ cursor: 'pointer' }}
                />
            </div>
            <div style={{ width: '100%', height: 40, position: 'relative' }}>
                <div className={`${classes.slogan} ${open ? classes.sloganActive : ''}`}>Powered by Bright Innovation</div>
                <IconButton
                    onClick={open ? handleDrawerClose : handleDrawerOpen}
                    className={`${classes.expandBtn} ${open ? classes.expandBtnActive : ''}`}
                >
                    {<ArrowExpandIcon direction={open ? 'left' : 'right'} />}
                </IconButton>
            </div>
            <List>
                {menuList.map((item) => (
                    <div
                        className={`${classes.menuItemWrapper} ${
                            open && expandedMenuId.includes(item.id) && item.children?.length
                                ? classes.menuItemExpanded
                                : ''
                        } ${initialTabId.includes(item.id) ? classes.backgroundActive : ''}`}
                        key={item.id}
                    >
                        <div onClick={() => handleTabClick(item?.path, item.id)}>
                            <ListItem
                                button
                                className={`${classes.menuItem} ${item.isActive ? classes.menuItemActive : ''}`}
                            >
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText primary={item.text} classes={{ primary: classes.menuItemText }} />
                                {item?.children && (
                                    <ExpandedIcon
                                        direction={
                                            expandedMenuId.includes(item.id) && item.children?.length ? 'up' : 'down'
                                        }
                                    />
                                )}
                            </ListItem>
                        </div>
                        {open &&
                            item.children?.map((item) => (
                                <div key={item.id} className={classes.itemChildWrapper}>
                                    <div onClick={() => handleTabClick(item.path, item.id)}>
                                        <ListItem
                                            button
                                            className={`${classes.menuItem} ${classes.menuItemChild} ${
                                                item.isActive ? classes.menuItemActive : ''
                                            } ${expandedMenuId.includes(item.id) ? classes.menuItemExpanded : ''}`}
                                        >
                                            <ListItemText
                                                primary={item.text}
                                                classes={{ primary: classes.menuItemText }}
                                            />
                                        </ListItem>
                                    </div>
                                </div>
                            ))}
                    </div>
                ))}
            </List>
        </Drawer>
    )
}

export default MenuBar
