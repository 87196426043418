import { AccentButton } from '@/components/atoms/Button/AccentButton'
import SearchBar from '@/components/atoms/SearchBar'
import SelectField from '@/components/atoms/SelectField'
import { ALL_VALUE, PLAN_OPTIONS, STATUS_OPTIONS } from '@/constant/options'
import React from 'react'

interface TSesionTableCompanies {
    status: string | number
    setStatus: React.Dispatch<React.SetStateAction<string | number>>
    plan: string | number
    setPlan: React.Dispatch<React.SetStateAction<string | number>>
    searchTerm: string
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>
    setPage: React.Dispatch<React.SetStateAction<number>>
    onCreateCompany: () => void
}

function SesionTableCompanies(props: TSesionTableCompanies) {
    const handlePlanChange = (id: number) => {
        props.setPage(0)
        props.setPlan(id)
    }
    const handleStatusChange = (id: number) => {
        props.setPage(0)
        props.setStatus(id)
    }
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setPage(0)
        props.setSearchTerm(e.target.value)
    }

    return (
        <div className="mt-40 company-header display-flex justify-space-btwn">
            <AccentButton className="width-150" onClick={props.onCreateCompany}>
                企業作成
            </AccentButton>
            <div className="display-flex justify-space-btwn width-900">
                <div className="width-400">
                    <SearchBar
                        value={props.searchTerm}
                        setValue={props.setSearchTerm}
                        size="small"
                        placeholder="企業名称で検索"
                        onChange={handleSearchChange}
                    />
                </div>
                {/* <div className="display-flex item-center">
                    <label className="fz-14 fw-600 width-100 text-right">プラン</label>
                    <SelectField
                        className="width-150 ml-10"
                        value={props.plan}
                        onChange={(e) => handlePlanChange(e.target.value as number)}
                        size="small"
                        menuItems={[ALL_VALUE, ...PLAN_OPTIONS]}
                    />
                </div> */}
                <div className="display-flex item-center">
                    <label className="fz-14 fw-600 width-100 text-right">ステータス</label>
                    <SelectField
                        className="width-150 ml-10"
                        value={props.status}
                        onChange={(e) => handleStatusChange(e.target.value as number)}
                        size="small"
                        menuItems={[ALL_VALUE, ...STATUS_OPTIONS]}
                    />
                </div>
            </div>
        </div>
    )
}

export default SesionTableCompanies
