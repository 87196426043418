import { PLAN_OPTIONS, STATUS_TYPE } from '@/constant/options'
import { Organization } from '@/openapi'
import styles from '@/utils/style'
import { tableConfigure } from '@/utils/tableConfigure'
import Box from '@mui/material/Box'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import React from 'react'
import CustomPagination, { TPagination } from '../Pagination'

interface TCompaniesTable {
    data: Organization[]
    pagination: TPagination
    loading?: boolean
    onShowSiteModal: (id: number) => void
    onShowUserModal: (id: number) => void
    onShowCompanyModal: (id: number) => void
}

function CompaniesTable(props: TCompaniesTable) {
    const style = {
        minHeight: 300,
        ...styles.myGrid,
    }

    const columnsCompaniesTable: GridColumns = [
        {
            field: 'name',
            headerName: '企業名称',
            minWidth: 300,
            sortable: false,
            headerClassName: 'fw-600',
            cellClassName: 'fw-600',
            headerAlign: 'center',
        },
        // {
        //     field: 'planId',
        //     headerName: 'プラン',
        //     minWidth: 150,
        //     headerClassName: 'fw-600',
        //     sortable: false,
        //     renderCell: (row) => PLAN_OPTIONS.find((plan) => plan.id === row.value)?.value || '',
        // },
        {
            field: 'siteNumber',
            headerName: '拠点数',
            minWidth: 200,
            headerClassName: 'fw-600',
            sortable: false,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'userNumber',
            headerName: 'ユーザー数',
            minWidth: 200,
            headerClassName: 'fw-600',
            sortable: false,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'status',
            headerName: 'ステータス',
            minWidth: 200,
            headerClassName: 'fw-600',
            sortable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (row) => (
                <span className={row.value === 1 ? 'color-success' : ''}>
                    {row.value === 1 ? STATUS_TYPE.active : STATUS_TYPE.deactive}
                </span>
            ),
        },
        {
            field: 'createdDate',
            headerName: '作成日',
            minWidth: 200,
            headerClassName: 'fw-600',
            sortable: false,
            renderCell: (row) => {
                const date = new Date(row.value).toLocaleDateString('en-GB')
                return date
            },
        },
        {
            field: 'actions',
            headerName: 'アクション',
            align: 'center',
            minWidth: 200,
            width: 300,
            headerClassName: 'fw-600',
            sortable: false,
            headerAlign: 'center',
            renderCell: ({ row }) => {
                return (
                    <div className="display-flex">
                        <div
                            className="action-btn fw-600 fz-12 cursor-pointer color-primary"
                            onClick={() => props.onShowSiteModal(row.id)}
                        >
                            拠点編集
                        </div>
                        <div
                            className="action-btn ml-20 fw-600 fz-12 cursor-pointer color-primary"
                            onClick={() => props.onShowUserModal(row.id)}
                        >
                            ユーザー編集
                        </div>
                        <div
                            className="action-btn ml-20 fw-600 fz-12 cursor-pointer color-primary"
                            onClick={() => props.onShowCompanyModal(row.id)}
                        >
                            企業編集
                        </div>
                    </div>
                )
            },
        },
    ]

    return (
        <Box>
            <DataGrid
                {...tableConfigure}
                sx={{ ...style }}
                autoHeight={!!props.data.length}
                columns={columnsCompaniesTable}
                rows={props.data}
                loading={props.loading}
                getRowClassName={() => 'font-default'}
            />
            <CustomPagination {...props.pagination} />
        </Box>
    )
}

export default CompaniesTable
