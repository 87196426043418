import CustomModal from '@/components/atoms/CustomModal'
import CreateCompanyForm from '@/components/molecules/Form/createCompany'
import { errors } from '@/constant/errors'
import ReactHookForm from '@/providers/ReactHookForm'
import { TAllFormValues } from '@/react-hook-form/types'
import { schemaCreateCompany } from '@/react-hook-form/validations'
import { AppDispatch } from '@/store'
import { createCompany, setCompanyError } from '@/store/slices/companiesSlice'
import { setMessage } from '@/store/slices/messageSlice'
import { AxiosError } from 'axios'
import React from 'react'
import { useDispatch } from 'react-redux'

export interface TModalCompany {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function ModalCreateCompany(props: TModalCompany) {
    const dispatch = useDispatch<AppDispatch>()

    const handleClose = () => {
        props.setOpen(false)
        dispatch(setCompanyError(''))
    }

    const handleSubmit = async (values: TAllFormValues['CreateCompany']) => {
        try {
            const response = await dispatch(createCompany(values))
            const error = response.payload as AxiosError
            if (error.response) {
                dispatch(
                    setMessage({
                        message: error.response.data?.errors[0].message || errors.ERROR_OCCURRED,
                        type: 'error',
                    }),
                )
                return
            } else {
                dispatch(setMessage({ message: '企業の作成に成功しました！', type: 'success' }))
            }
            handleClose()
        } catch (err) {}
    }

    const defaultValues = {
        name: '',
        planId: 3,
        startMonth: '',
        sites: [],
        users: [],
    }

    return (
        <CustomModal width={800} title="企業作成" open={props.open} onClose={handleClose}>
            <ReactHookForm validateSchema={schemaCreateCompany} defaultValues={defaultValues}>
                <CreateCompanyForm onSubmit={handleSubmit} onClose={handleClose} />
            </ReactHookForm>
        </CustomModal>
    )
}

export default ModalCreateCompany
