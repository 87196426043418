import CheckIcon from '@/components/atoms/Icon/CheckIcon'
import ChevronDownIcon from '@/components/atoms/Icon/ChevronDownIcon'
import ChevronRightIcon from '@/components/atoms/Icon/ChevronRightIcon'
import DeleteIcon from '@/components/atoms/Icon/DeleteIcon'
import EditIcon from '@/components/atoms/Icon/EditIcon'
import PlusCircleIcon from '@/components/atoms/Icon/PlusCircleIcon'
import { SiteNode } from '@/openapi'
import { getMaxDepth } from '@/utils/siteNodeHelper'
import { Button, IconButton, Input, Dialog, DialogContent, DialogTitle, DialogActions, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'

// 拠点ツリービューの最大階層数
const MAX_LEVEL = 5

const useStyles = makeStyles((theme: Theme) => ({
    nodeContainer: {
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    nodeContent: {
        display: 'flex',
        alignItems: 'center',
    },
    nodeLabel: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        height: '40px',
        width: '256px',
        padding: '8px 54px 8px 12px',
    },
    activityEntered: {
        borderLeft: `8px solid ${theme.palette.info.main}`,
    },
    deleted: {
        backgroundColor: '#EFEFEF!important'
    },
    highlightNodeLabel: {
        border: `2px solid rgb(19, 111, 170)!important`,
    },
    nodeLabelText: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        minWidth: '100px',
        maxWidth: '200px',
    },
    nodeActions: {
        position: 'absolute',
        right: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
    },
    iconButton: {
        width: '20px',
        height: '20px',
        marginLeft: '7px',
        marginRight: '5px',
        padding: 0,
    },
    childrenContainer: {
        borderLeft: `1px solid ${theme.palette.divider}`,
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1),
        paddingLeft: '256px',
    },
    siteNameInput: {
        fontSize: '12px',
        padding: '0!important',
    },
}))

interface SiteNodeViewProps {
    siteNode: SiteNode
    level: number
    keyword?: string
    onAdd: (parentSiteId: number) => void
    onUpdate: (siteId: number, newName: string) => void
    onDelete: (siteId: number) => void
    onMove: (draggedSiteId: number, targetSiteId: number) => void
}

const SiteNodeView: React.FC<SiteNodeViewProps> = ({ siteNode, level, keyword, onAdd, onUpdate, onDelete, onMove }) => {
    const [name, setName] = useState<string>(siteNode.title)
    const [expanded, setExpanded] = useState<boolean>(true)
    const [editing, setEditing] = useState<boolean>(false)
    const [hovered, setHovered] = useState<boolean>(false)
    const [visible, setVisible] = useState<boolean>(false)
    const [deleted, setDeleted] = useState<boolean>(siteNode.deleted)
    const [showAddRestrictionByEnteredSrcActivityDialog, setShowAddRestrictionByEnteredSrcActivityDialog] = useState<boolean>(false)
    const [showMaxLevelDialog, setShowMaxLevelDialog] = useState<boolean>(false)
    const [showMoveRestrictionByEnteredDestActivityDialog, setShowMoveRestrictionByEnteredDestActivityDialog] = useState<boolean>(false)
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
    const [showDeleteRestrictionDialog, setShowDeleteRestrictionDialog] = useState<boolean>(false)
    const classes = useStyles()

    useEffect(() => {
        if (!name) {
            // 新規追加された拠点を即時編集モードにする
            setEditing(true)
        }
    }, [name])

    useEffect(() => {
        if (!keyword) {
            setVisible(true)
        } else {
            setVisible(containKeyword(siteNode, keyword))
        }
    }, [keyword])

    const handleEdit = (e: React.MouseEvent) => {
        e.stopPropagation()
        setEditing(true)
    }

    const handleSave = (e: React.MouseEvent) => {
        e.stopPropagation()
        if (name.trim()) {
            onUpdate(siteNode.siteId!, name.trim())
            setEditing(false)
        }
    }

    const handleAdd = (e: React.MouseEvent, level: number) => {
        e.stopPropagation()
        if (siteNode.isActivityEntered) {
            setShowAddRestrictionByEnteredSrcActivityDialog(true)
            return
        }
        if (level + 1 > MAX_LEVEL) {
            setShowMaxLevelDialog(true)
            return
        }
        onAdd(siteNode.siteId!)
    }

    const handleDelete = (e: React.MouseEvent) => {
        e.stopPropagation()
        // 経由拠点の削除を抑制
        if (siteNode.children.length > 0) {
            setShowDeleteRestrictionDialog(true)
            return
        }
        setShowDeleteDialog(true)
    }

    const confirmDelete = () => {
        onDelete(siteNode.siteId!)
        setShowDeleteDialog(false)
        setDeleted(true)
    }

    const handleDragStart = (e: React.DragEvent) => {
        e.stopPropagation()
        if (editing) {
            return
        }
        e.dataTransfer.setData('application/json', JSON.stringify({ id: siteNode.siteId, level, isActivityEntered: siteNode.isActivityEntered, children: siteNode.children }))
        e.dataTransfer.effectAllowed = 'move'
    }

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault()
        e.stopPropagation()
        e.dataTransfer.dropEffect = 'move'
    }

    const handleDrop = (e: React.DragEvent) => {
        e.preventDefault()
        e.stopPropagation()
        if (editing) {
            return
        }
        try {
            const data = JSON.parse(e.dataTransfer.getData('application/json'))
            if (siteNode.isActivityEntered) {
                setShowMoveRestrictionByEnteredDestActivityDialog(true)
                return
            }
            if (data.id !== siteNode.siteId) {
                const srcNodeDepth = getMaxDepth(data)
                const newLevel = level + srcNodeDepth
                if (newLevel <= MAX_LEVEL) {
                    onMove(data.id, siteNode.siteId!)
                } else {
                    setShowMaxLevelDialog(true)
                }
            }
        } catch (error) {
            console.error('Error parsing drag data:', error)
        }
    }

    const handleToggle = (e: React.MouseEvent) => {
        e.stopPropagation()
        setExpanded(!expanded)
    }

    const containKeyword = (siteNode: SiteNode, keyword: string) => {
        const matchedKeyword = matchesKeyword(siteNode.title, keyword)
        const matchedChildren = siteNode.children.some((child) => {
            return containKeyword(child, keyword)
        })
        return matchedKeyword || matchedChildren
    }

    const matchesKeyword = (name: string, keyword: string) => {
        return name.toLowerCase().includes(keyword.toLowerCase())
    }

    return (
        <>
            {visible && (
                <div
                    className={classes.nodeContainer}
                    draggable
                    onDragStart={handleDragStart}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                >
                    <div
                        className={classes.nodeContent}
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
                    >
                        {siteNode.children && siteNode.children.length > 0 ? (
                            <IconButton onClick={handleToggle} className={classes.iconButton}>
                                {expanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
                            </IconButton>
                        ) : (
                            <div style={{ width: '1.5rem', marginRight: '0.5rem' }} />
                        )}
                        <div className={`${classes.nodeLabel} ${deleted ?  classes.deleted : '' } ${siteNode.isActivityEntered ? classes.activityEntered : ''}`}>
                            <div className={classes.nodeLabelText}>
                                {editing ? (
                                    <Input
                                        className={classes.siteNameInput}
                                        value={name}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                                        fullWidth
                                        placeholder='拠点名'
                                        autoFocus
                                        onClick={(e: React.MouseEvent) => e.stopPropagation()}
                                        error={!name.length}
                                    />
                                ) : (
                                    <span>{name}</span>
                                )}
                            </div>
                            {!deleted && hovered && !editing && (
                                <div className={classes.nodeActions}>
                                    <IconButton onClick={handleEdit} className={classes.iconButton}>
                                        <EditIcon
                                            width={14}
                                            height={14}
                                        />
                                    </IconButton>
                                    <IconButton onClick={handleDelete} className={classes.iconButton}>
                                        <DeleteIcon
                                            width={14}
                                            height={14}
                                        />
                                    </IconButton>
                                </div>
                            )}
                            {editing && (
                                <IconButton onClick={handleSave} className={classes.iconButton}>
                                    <CheckIcon />
                                </IconButton>
                            )}
                        </div>
                        {level < MAX_LEVEL && (
                            <IconButton onClick={(e) => { handleAdd(e, level) }} className={classes.iconButton}>
                                <PlusCircleIcon />
                            </IconButton>
                        )}
                    </div>
                    {expanded && siteNode.children && siteNode.children.length > 0 && (
                        <div className={classes.childrenContainer}>
                            {siteNode.children.map((child) => (
                                <SiteNodeView
                                    key={child.siteId}
                                    siteNode={child}
                                    level={level + 1}
                                    onAdd={onAdd}
                                    onUpdate={onUpdate}
                                    onDelete={onDelete}
                                    onMove={onMove}
                                />
                            ))}
                        </div>
                    )}
                    <Dialog open={showMaxLevelDialog} onClose={() => setShowMaxLevelDialog(false)}>
                        <DialogContent>
                            <DialogTitle>追加できません</DialogTitle>
                            <p>最大階層数（{MAX_LEVEL}階層）を超えるため、<br />この拠点には、これ以上拠点を追加できません。</p>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setShowMaxLevelDialog(false)}>閉じる</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={showAddRestrictionByEnteredSrcActivityDialog} onClose={() => setShowAddRestrictionByEnteredSrcActivityDialog(false)}>
                        <DialogContent>
                            <DialogTitle>追加できません</DialogTitle>
                            <p>対象の拠点に活動量が入力されています。</p>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setShowAddRestrictionByEnteredSrcActivityDialog(false)}>閉じる</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={showMoveRestrictionByEnteredDestActivityDialog} onClose={() => setShowMoveRestrictionByEnteredDestActivityDialog(false)}>
                        <DialogContent>
                            <DialogTitle>移動できません。</DialogTitle>
                            <p>移動先の拠点に活動量が入力されています。</p>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setShowMoveRestrictionByEnteredDestActivityDialog(false)}>閉じる</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)}>
                        <DialogContent>
                            <DialogTitle>拠点の削除</DialogTitle>
                            <p>対象の拠点を削除します。よろしいですか？<br />※ 削除した結果は、「入力を保存する」ボタンを押下するまでは保存されません。</p>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setShowDeleteDialog(false)}>キャンセル</Button>
                            <Button onClick={confirmDelete} color='secondary'>削除</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={showDeleteRestrictionDialog} onClose={() => setShowDeleteRestrictionDialog(false)}>
                        <DialogContent>
                            <DialogTitle>削除できません。</DialogTitle>
                            <p>対象の拠点に、子拠点が紐づいています。</p>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setShowDeleteRestrictionDialog(false)}>閉じる</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )}
        </>
    )
}

export default SiteNodeView
