import ChevronDownIcon from '@/components/atoms/Icon/ChevronDownIcon'
import ChevronRightIcon from '@/components/atoms/Icon/ChevronRightIcon'
import { SiteNode } from '@/openapi'
import { IconButton, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'

const useStyles = makeStyles((theme: Theme) => ({
    nodeContainer: {
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    selected: {
        backgroundColor: '#EDF2F5!important',
        border: `1px solid #5B8AAD!important`,
    },
    deSelected: {
        backgroundColor: theme.palette.background.paper,
    },
    nodeContent: {
        display: 'flex',
        alignItems: 'center',
    },
    nodeLabel: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        cursor: 'pointer',
        height: '40px',
        width: '256px',
        padding: '8px 54px 8px 12px',
    },
    highlightNodeLabel: {
        border: `2px solid rgb(19, 111, 170)!important`,
    },
    nodeLabelText: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        minWidth: '100px',
        maxWidth: '200px',
    },
    nodeActions: {
        position: 'absolute',
        right: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
    },
    iconButton: {
        width: '20px',
        height: '20px',
        marginLeft: '7px',
        marginRight: '5px',
        padding: 0,
    },
    childrenContainer: {
        borderLeft: `1px solid ${theme.palette.divider}`,
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1),
        paddingLeft: '16px',
    },
    siteNameInput: {
        fontSize: '12px',
        padding: '0!important',
    },
}))

interface SelectableSiteNodeViewProps {
    siteNode: SiteNode
    level: number
    selectedSiteIds: number[]
    keyword?: string
    onSelect: (siteId: number) => void
    onDeSelect: (siteId: number) => void
}

const SelectableSiteNodeView: React.FC<SelectableSiteNodeViewProps> = ({ siteNode, level, selectedSiteIds, keyword, onSelect, onDeSelect }) => {
    const [name, _] = useState<string>(siteNode.title)
    const [selected, setSelected] = useState<boolean>(false)
    const [expanded, setExpanded] = useState<boolean>(true)
    const [visible, setVisible] = useState<boolean>(false)
    const classes = useStyles()

    useEffect(() => {
        if (!siteNode.siteId) {
            return
        }
        const contains = selectedSiteIds.some((siteId) => siteId === siteNode.siteId)
        setSelected(contains)
    }, [selectedSiteIds])

    useEffect(() => {
        if (!keyword) {
            setVisible(true)
        } else {
            setVisible(containKeyword(siteNode, keyword))
        }
    }, [keyword])

    const handleClickView = (e: React.MouseEvent) => {
        e.stopPropagation()
        if (!siteNode.siteId) {
            return
        }
        if (selected) {
            onDeSelect(siteNode.siteId)
            setSelected(false)
        } else {
            onSelect(siteNode.siteId)
            setSelected(true)
        }
    }

    const handleClickChevron = (e: React.MouseEvent) => {
        e.stopPropagation()
        setExpanded(!expanded)
    }

    const containKeyword = (siteNode: SiteNode, keyword: string) => {
        const matchedKeyword = matchesKeyword(siteNode.title, keyword)
        const matchedChildren = siteNode.children.some((child) => {
            return containKeyword(child, keyword)
        })
        return matchedKeyword || matchedChildren
    }

    const matchesKeyword = (name: string, keyword: string) => {
        return name.toLowerCase().includes(keyword.toLowerCase())
    }

    return (
        <>
            {visible && (
                <div
                    className={classes.nodeContainer}
                    onClick={handleClickView}
                >
                    <div className={classes.nodeContent}>
                        {siteNode.children && siteNode.children.length > 0 ? (
                            <IconButton onClick={handleClickChevron} className={classes.iconButton}>
                                {expanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
                            </IconButton>
                        ) : (
                            <div style={{ width: '1.5rem', marginRight: '0.5rem' }} />
                        )}
                        <div className={`${classes.nodeLabel} ${selected ? classes.selected : '' }`}>
                            <div className={classes.nodeLabelText}>
                                <span>{name}</span>
                            </div>
                        </div>
                    </div>
                    {expanded && siteNode.children && siteNode.children.length > 0 && (
                        <div className={classes.childrenContainer}>
                            {siteNode.children.map((child) => (
                                <SelectableSiteNodeView
                                    key={child.siteId}
                                    siteNode={child}
                                    level={level + 1}
                                    selectedSiteIds={selectedSiteIds}
                                    onSelect={onSelect}
                                    onDeSelect={onDeSelect}
                                />
                            ))}
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

export default SelectableSiteNodeView
