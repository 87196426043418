import { AccentButton } from '@/components/atoms/Button/AccentButton'
import ButtonRounded from '@/components/atoms/Button/ButtonRounded'
import ErrorText from '@/components/atoms/ErrorText'
import InputField from '@/components/atoms/InputField'
import SelectField from '@/components/atoms/SelectField'
import { MONTH_OPTIONS, PLAN_OPTIONS, ROLE_OPTIONS } from '@/constant/options'
import { useTypedForm } from '@/hooks/useTypedForm'
import { TAllFormValues } from '@/react-hook-form/types'
import { companyStore } from '@/store/slices/companiesSlice'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Button } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { Controller, SubmitHandler } from 'react-hook-form'
import { useSelector } from 'react-redux'

interface TCreateCompanyForm {
    onSubmit: SubmitHandler<TAllFormValues['CreateCompany']>
    onClose: () => void
}

function CreateCompanyForm(props: TCreateCompanyForm) {
    const { error: storeError } = useSelector(companyStore)

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useTypedForm('CreateCompany')

    return (
        <form onSubmit={handleSubmit(props.onSubmit)} className="create-company-form">
            <div className="comapy-static-info px-100">
                <div className="mt-20">
                    <Controller
                        render={({ field: { ref, ...rest } }) => {
                            return (
                                <InputField
                                    {...rest}
                                    size="small"
                                    id="name"
                                    isRequired
                                    label="企業名称"
                                    widthlabel={120}
                                    error={!!errors.name?.message ?? false}
                                    helperText={errors.name?.message}
                                />
                            )
                        }}
                        name="name"
                        control={control}
                    />
                </div>
                {/* <div className="flex-center mt-20">
                    <Controller
                        render={({ field: { ref, ...rest } }) => {
                            return (
                                <SelectField
                                    {...rest}
                                    id="planId"
                                    label="プラン"
                                    error={!!errors.planId?.message ?? false}
                                    widthlabel={120}
                                    size="small"
                                    menuItems={PLAN_OPTIONS}
                                    isRequired
                                    helperText={errors.planId?.message}
                                />
                            )
                        }}
                        name="planId"
                        control={control}
                    />
                </div> */}
                <div className="flex-center mt-20">
                    <Controller
                        render={({ field: { ref, ...rest } }) => {
                            return (
                                <SelectField
                                    {...rest}
                                    id="startMonth"
                                    label="開始月"
                                    error={!!errors.startMonth?.message ?? false}
                                    widthlabel={120}
                                    size="small"
                                    menuItems={MONTH_OPTIONS}
                                    isRequired
                                    helperText={errors.startMonth?.message}
                                />
                            )
                        }}
                        name="startMonth"
                        control={control}
                    />
                </div>
            </div>
            {storeError && <ErrorText text={storeError} className="mt-10 text-center" />}
            <div className="mt-40 mb-10 flex-center">
                <ButtonRounded className="company-form-btn" types="outline" onClick={props.onClose}>
                    キャンセル
                </ButtonRounded>
                <ButtonRounded className="company-form-btn ml-20" type="submit" types="filled">
                    保存
                </ButtonRounded>
            </div>
        </form>
    )
}

export default CreateCompanyForm
