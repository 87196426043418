import { AccentButton } from '@/components/atoms/Button/AccentButton'
import ButtonRounded from '@/components/atoms/Button/ButtonRounded'
import ErrorText from '@/components/atoms/ErrorText'
import InputField from '@/components/atoms/InputField'
import NoRowsOverlay from '@/components/atoms/NoRowOverlay'
import SelectField from '@/components/atoms/SelectField'
import AlertDialogSlide from '@/components/molecules/Dialog/confirm'
import { ROLE_OPTIONS } from '@/constant/options'
import { useTypedForm } from '@/hooks/useTypedForm'
import { TAllFormValues, TUser, TUsers } from '@/react-hook-form/types'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import { Button } from '@mui/material'
import { Box } from '@mui/system'
import React, { SetStateAction, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useFieldArray } from 'react-hook-form'
import { UseFieldArrayUpdate } from 'react-hook-form/dist/types'
import CustomPagination, { TPagination } from '../Pagination'

interface TEditUserForm {
    onClose: () => void
    onSubmit: SubmitHandler<TAllFormValues['EditUsers']>
    onDelete: (siteId: string) => any
    onShowSiteSelectModal: (userId: string) => void
    pagination: TPagination
    defaultValues: { users: TUser[] }
    state: {
        addMode: boolean
        setAddMode: React.Dispatch<SetStateAction<boolean>>
        itemEditing: number | null
        setItemEditing: React.Dispatch<SetStateAction<number | null>>
        error: string
    }
}

export default function EditUserForm(props: TEditUserForm) {
    const [showConfirm, setShowConfirm] = useState(false)
    const [user, setUser] = useState<TUser>()

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useTypedForm('EditUsers')

    useEffect(() => {
        reset(props.defaultValues)
    }, [props.defaultValues])

    const { fields, append, remove, update } = useFieldArray({
        control,
        name: 'users',
    })

    const handleEditClick = (i: number, fieldValue: TUser, update: UseFieldArrayUpdate<TUsers, 'users'>) => {
        if (errors['users'] && errors['users'].length) return
        if (props.state.itemEditing || props.state.itemEditing === 0) props.state.setItemEditing(null)
        else {
            update(i, { ...fieldValue, isChange: true })
            props.state.setItemEditing(i)
        }
        props.state.setAddMode(false)
    }

    return (
        <form className="px-50" onSubmit={handleSubmit(props.onSubmit)}>
            <AlertDialogSlide
                title="このユーザーを削除してもよろしいですか?"
                open={showConfirm}
                setOpen={setShowConfirm}
                onOk={async () => {
                    if (user?.userId) {
                        props.onDelete(user.userId)
                        props.state.setItemEditing(null)
                    }
                    setShowConfirm(false)
                }}
            >
                ユーザー名: {user?.name}
                <br />
                Eメール: {user?.email}
            </AlertDialogSlide>
            <div className="mt-40">
                <AccentButton
                    startIcon={<AddRoundedIcon />}
                    onClick={() => {
                        append({ name: '', email: '', roleId: 2, isChange: true, isNewUser: true })
                        props.state.setItemEditing(fields.length)
                        props.state.setAddMode(true)
                    }}
                    disabled={props.state.itemEditing !== null}
                >
                    追加
                </AccentButton>
            </div>
            {fields.length ? (
                <div className="flex-center mt-20 ml-20" style={{ columnGap: 16 }}>
                    <span style={{ width: 40 }} className="text-center fw-600 fz-14"></span>
                    <span style={{ width: 140 }} className="text-center fw-600 fz-14">
                        名前
                    </span>
                    <span style={{ width: 220 }} className="text-center ml-200 fw-600 fz-14">
                        Eメール
                    </span>
                    <span style={{ width: 150 }} className="text-center fw-600 fz-14">
                        権限
                    </span>
                    <span style={{ width: 150 }} className="text-center fw-600 fz-14">
                        所属
                    </span>
                </div>
            ) : null}
            <div className="mt-20">
                {fields.length ? (
                    fields.map((field, i) => (
                        <div
                            key={field.id}
                            className="form-list-item display-flex item-center mt-10"
                            style={{ columnGap: 15 }}
                        >
                            <Button
                                className="remove-btn cursor-pointer"
                                onClick={() => {
                                    setShowConfirm(true)
                                    setUser({ ...field })
                                }}
                                color="inherit"
                                disabled={props.state.itemEditing !== null}
                            >
                                <CloseRoundedIcon sx={{ margin: '5px 0 0' }} />
                            </Button>
                            <span className="count ma-0">{i + 1}</span>
                            <Box sx={{ width: 220 }}>
                                <Controller
                                    name={`users.${i}.name`}
                                    render={({ field: { ref, ...rest } }) => {
                                        return (
                                            <InputField
                                                {...rest}
                                                size="small"
                                                InputProps={{
                                                    readOnly: props.state.itemEditing !== i,
                                                }}
                                                error={
                                                    errors['users'] && errors['users'][i]?.name?.message ? true : false
                                                }
                                                helperText={
                                                    errors['users'] &&
                                                    (errors['users'][i]?.name?.message ||
                                                        (errors['users'][i]?.email?.message ? ' ' : null))
                                                }
                                            />
                                        )
                                    }}
                                />
                            </Box>
                            <Box sx={{ width: 315 }}>
                                <Controller
                                    name={`users.${i}.email`}
                                    render={({ field: { ref, ...rest } }) => {
                                        return (
                                            <InputField
                                                {...rest}
                                                size="small"
                                                InputProps={{
                                                    readOnly: props.state.itemEditing !== i,
                                                }}
                                                error={
                                                    errors['users'] && errors['users'][i]?.email?.message ? true : false
                                                }
                                                helperText={
                                                    errors['users'] &&
                                                    (errors['users'][i]?.email?.message ||
                                                        (errors['users'][i]?.name?.message ? ' ' : null))
                                                }
                                            />
                                        )
                                    }}
                                />
                            </Box>
                            <Box sx={{ width: 150 }}>
                                <Controller
                                    name={`users.${i}.roleId`}
                                    render={({ field: { ref, ...rest } }) => (
                                        <SelectField
                                            {...rest}
                                            className={`width-150 ${
                                                props.state.itemEditing === i ? '' : 'pointer-event-none'
                                            }`}
                                            size="small"
                                            menuItems={ROLE_OPTIONS.slice(1)}
                                            helperText={
                                                errors['users'] &&
                                                (errors['users'][i]?.email || errors['users'][i]?.name)
                                                    ? ' '
                                                    : null
                                            }
                                        />
                                    )}
                                />
                            </Box>
                            <Box sx={{ width: 150 }}>
                                <div
                                    className={`text-center action-btn fw-600 fz-12 color-primary ${!field.userId ? 'color-disabled' : 'cursor-pointer'}`}
                                    onClick={() => {
                                        if (!field.userId) {
                                            return
                                        }
                                        props.onShowSiteSelectModal(field.userId)
                                    }}
                                >
                                    拠点選択
                                </div>
                            </Box>
                            {(field.isNewUser && props.state.addMode) || (errors['users'] && errors['users'][i]) ? (
                                <Button type="submit">
                                    <CheckRoundedIcon />
                                </Button>
                            ) : (
                                <Button
                                    onClick={() => handleEditClick(i, field, update)}
                                    color={props.state.itemEditing === i ? 'primary' : 'inherit'}
                                    disabled={
                                        (props.state.itemEditing !== null && props.state.itemEditing !== i) ||
                                        !!errors['users']?.length
                                    }
                                    type={props.state.itemEditing !== i ? 'submit' : 'button'}
                                >
                                    {props.state.itemEditing === i ? <CheckRoundedIcon /> : <EditRoundedIcon />}
                                </Button>
                            )}
                        </div>
                    ))
                ) : (
                    <div>
                        <NoRowsOverlay />
                    </div>
                )}
            </div>
            {props.defaultValues.users.length ? (
                <div className="mt-10">
                    <CustomPagination {...props.pagination} />
                </div>
            ) : null}
            {props.state.error && <ErrorText className={'text-center'} text={props.state.error} />}
            <div className="flex-center ma-20">
                <ButtonRounded className="company-form-btn" types="outline" onClick={props.onClose}>
                    キャンセル
                </ButtonRounded>
            </div>
        </form>
    )
}
