import AccountOptions from '@/components/atoms/DialogOptions/AccountOptions'
import BusinessIcon from '@/components/atoms/Icon/BusinessIcon'
import DashboardIcon from '@/components/atoms/Icon/DashboardIcon'
import NotificationsIcon from '@/components/atoms/Icon/NotificationsIcon'
import PersonIcon from '@/components/atoms/Icon/PersonIcon'
import { OverflowTip } from '@/components/atoms/OverflowTip'
import { routes } from '@/routes'
import { emissionStore } from '@/store/slices/emissionSlice'
import theme from '@/theme'
import caches from '@/utils/cache'
import { AppBar, Backdrop, Button, Collapse, Theme, Toolbar } from '@mui/material'
import { makeStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import clsx from 'clsx'
import { navigate } from 'gatsby'
import React, { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'

export type TMenuItem = {
    id: number
    text: string
    icon: JSX.Element
    path?: string
    isActive?: boolean
    children?: Required<Omit<TMenuItem, 'icon' | 'children'>>[]
}
interface THeader {
    open: boolean
    drawerWidth: number
}
export interface UnReadNotification {
    status: boolean
    quantity: number
}

function Header(props: THeader) {
    const [showSettingOption, setShowSettingOption] = useState(false)
    const [showAccountOption, setShowAccountOption] = useState(false)
    const { emissionFactor } = useSelector(emissionStore)

    const isEditEmissionFactor = useMemo(() => {
        return Boolean(emissionFactor.id)
    }, [emissionFactor.id])

    const path = typeof window !== 'undefined' ? window.location.pathname : false
    const userName = caches.get('admin_info_user') || 'システム管理者'

    const styleOptionBtn = {
        fontSize: 14,
        width: 'max-content',
        display: 'flex',
        alignItems: 'center',
    }

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            appBar: {
                zIndex: 1204,
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                backgroundColor: '#fff',
                boxShadow: 'none',
                color: '#000',
            },
            appBarShift: {
                marginLeft: props.drawerWidth,
                width: `calc(100% - ${props.drawerWidth}px)`,
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
            toolbar: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: theme.spacing(0, 1),
                // necessary for content to be below app bar
                ...theme.mixins.toolbar,
            },
            toolBar: {
                padding: 0,
            },
            headerLogo: {
                maxWidth: 0,
                height: 74,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',

                overflow: 'hidden',
                transition: 'all 0.225s cubic-bezier(0.4, 0, 0.6, 1) 0ms',
            },
            headerLogoActive: {
                width: '100%',
                maxWidth: 72,
                borderRight: '1px solid rgba(0, 0, 0, 0.12);',
                backgroundColor: theme.colors.lightGray,
            },
            headerWrapper: {
                width: '100%',
                height: 74,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0 20px',
                boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px',
            },
            optionsWrapper: {
                minWidth: 450,
                columnGap: 20,
            },
            optionBtn: {
                ...styleOptionBtn,
            },
            optionBtnNameUser: {
                ...styleOptionBtn,
                color: '#42526E',
                maxWidth: 500,
                textTransform: 'unset',
            },
            flexCenter: {
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
            },
            settingOptions: {
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
            },
            collapse: {
                position: 'absolute',
                top: 45,
                right: 0,
                zIndex: 1204,
            },
            backdrop: {
                zIndex: 1201,
                color: '#fff',
            },

            companyName: {
                maxWidth: 450,
                fontSize: 12,
                fontWeight: 300,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                columnGap: 10,
                marginRight: 8,
            },
            headerText: {
                display: 'flex',
                alignItems: 'center',
                columnGap: 20,
                fontSize: 24,
                width: '90%',
                color: theme.colors.primaryN,
            },
            textBreadCrumbs: {
                color: '#000',
                textUnderlineOffset: '5px',
                textDecoration: 'underline',
                cursor: 'pointer',
            },
            comanyAvatar: {
                background: theme.colors.primaryN,
                width: 20,
                height: 20,
                fontSize: 11,
                fontWeight: 300,
            },
            activeCompanyLink: {
                textDecoration: 'underline',
                color: theme.colors.primaryN,
                cursor: 'pointer',
            },
        }),
    )
    const classes = useStyles()

    const handleBacdropClick = () => {
        setShowAccountOption(false)
        setShowSettingOption(false)
    }

    const headerTextList = [
        {
            route: routes.home,
            content: (
                <>
                    <BusinessIcon /> 企業一覧
                </>
            ),
        },
        {
            route: routes.emissionFactor,
            content: (
                <>
                    <DashboardIcon /> 係数設定 : 種別一覧
                </>
            ),
        },
        {
            route: routes.emissionFactorId,
            content: (
                <>
                    <DashboardIcon /> 係数設定 : 係数マスタ
                </>
            ),
        },
        {
            route: routes.notification,
            content: (
                <>
                    <NotificationsIcon /> お知らせ管理
                </>
            ),
        },
        {
            route: routes.addEditEmissionFactor,
            content: (
                <>
                    <DashboardIcon /> {`係数設定：係数マスタ > ${isEditEmissionFactor ? '係数編集' : '係数追加'}`}
                </>
            ),
        },
        {
            route: routes.PRIVACY_POLICY,
            content: (
                <span style={{ fontWeight: 600, fontSize: 22, color: theme.colors.primaryN }}>
                    プライバシーポリシー
                </span>
            ),
        },
    ]

    const headerText =
        headerTextList.find((h) => {
            const currRoute =
                (path as string).length > 1 && (path as string).charAt((path as string).length - 1) === '/'
                    ? (path as string).substr(0, (path as string).length - 1)
                    : path
            return h.route === currRoute
        })?.content || ''

    return (
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: props.open,
            })}
        >
            <Backdrop
                invisible
                open={showAccountOption || showSettingOption}
                className={classes.backdrop}
                onClick={handleBacdropClick}
            />
            <Toolbar classes={{ root: classes.toolBar }}>
                <div className={`${classes.headerLogo} ${props.open ? '' : classes.headerLogoActive}`}>
                    <img
                        src="/assets/logo/logo_EV_s.png"
                        alt="logo"
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate(routes.home)}
                        width="70px"
                    />
                </div>
                <div className={classes.headerWrapper}>
                    <div className={`${classes.headerText}`}>{headerText ? headerText : ''}</div>
                    <div>
                        <div className={`${classes.optionsWrapper} ${classes.flexCenter}`}>
                            <div className={classes.settingOptions}>
                                <span className={classes.optionBtnNameUser} style={{ marginRight: 10 }}>
                                    admin
                                </span>
                                <Button
                                    className={classes.optionBtnNameUser}
                                    startIcon={<PersonIcon />}
                                    onClick={() => setShowAccountOption((pre) => !pre)}
                                >
                                    <OverflowTip styles={{ fontWeight: 400 }}>{userName}</OverflowTip>
                                </Button>
                                <Collapse timeout={100} in={showAccountOption} className={classes.collapse}>
                                    <AccountOptions />
                                </Collapse>
                            </div>
                        </div>
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    )
}

export default Header
