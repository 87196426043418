import PrimaryButton from '@/components/atoms/Button/PrimaryButton'
import { CustomTooltip } from '@/components/atoms/CustomTooltip'
import FAQIcon from '@/components/atoms/Icon/FAQIcon'
import InputField from '@/components/atoms/InputField'
import SelectField, { MenuItemProps } from '@/components/atoms/SelectField'
import { YEAR_OPTIONS } from '@/constant/options'
import { useTypedForm } from '@/hooks/useTypedForm'
import { EnergySavingScope } from '@/openapi'
import { EmissionFactorFormTypes } from '@/pages/emission-factor-id/form'
import { TAllFormValues } from '@/react-hook-form/types'
import { routes } from '@/routes'
import { emissionStore } from '@/store/slices/emissionSlice'
import theme from '@/theme'
import { Box, FormControlLabel, FormControlLabelProps, FormLabel, IconButton, Radio, RadioGroup } from '@mui/material'
import { navigate } from 'gatsby'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, SubmitHandler } from 'react-hook-form'
import { useSelector } from 'react-redux'

interface AddEditEmissionFactor {
    isEdit: boolean
    types: EmissionFactorFormTypes
    onSubmit: SubmitHandler<TAllFormValues['addEditEmissionId']>
}

const radioStyles = (fontWeight: number = 300): FormControlLabelProps['sx'] => ({
    '& .MuiTypography-root': {
        marginLeft: '3px',
        fontWeight,
        fontSize: '14px',
        color: theme.colors.blackN,
    },
})
const inputStylyes: FormControlLabelProps['sx'] = {
    '& .MuiInputBase-input': { fontSize: '16px' },
    '& input': { '&::placeholder': { fontSize: '14px' } },
}
const selectStyles: FormControlLabelProps['sx'] = {
    fontSize: 16,
}

function AddEditEmissionFactor(props: AddEditEmissionFactor) {
    const [disabledBtn, setDisabledBtn] = useState(true)
    const { listEmissionFactorTable } = useSelector(emissionStore)
    const {
        control,
        handleSubmit,
        formState: { errors, isValid },
        watch,
        setValue,
    } = useTypedForm('addEditEmissionId')
    const [anti_warming, energy_saving, energy_saving_id, anti_warming_id] = watch([
        'anti_warming',
        'energy_saving',
        'energy_saving_id',
        'anti_warming_id',
    ])

    useEffect(() => {
        if (anti_warming.includes('None')) {
            setValue('anti_warming_id', 0)
        }
        if (energy_saving.includes('None')) {
            setValue('energy_saving_id', 0)
        }
        if (anti_warming === 'antiWarming' && anti_warming_id === 0) {
            setValue('anti_warming_id', 1)
        }
        if (energy_saving === 'energySaving' && energy_saving_id === 0) {
            setValue('energy_saving_id', 1)
        }
    }, [anti_warming, energy_saving, energy_saving_id, anti_warming_id])

    useEffect(() => {
        if (isValid) {
            const isAntiWarmingValid = anti_warming.includes('None')
            const isEnergySavingValid = energy_saving.includes('None')
            if ((isAntiWarmingValid || anti_warming_id) && (isEnergySavingValid || energy_saving_id))
                setDisabledBtn(false)
            else if ((isEnergySavingValid || energy_saving_id) && (isAntiWarmingValid || anti_warming_id))
                setDisabledBtn(false)
            else setDisabledBtn(true)
        } else {
            setDisabledBtn(true)
        }
    }, [isValid, anti_warming, energy_saving, energy_saving_id, anti_warming_id])

    const listFilters = useMemo(() => {
        return listEmissionFactorTable.map((i) => {
            return {
                id: i.id,
                value: `${i.id} - ${i.name}`,
            }
        })
    }, [listEmissionFactorTable])
    const listAntiWarmingTypes = props.types.antiWarmingTypes
        .map((a) => ({
            id: a.id,
            value: a.name,
        }))
        .filter((item) => item.id !== 10) as MenuItemProps[]

    return (
        <form
            autoComplete="off"
            className="full-width add-edit-emission-factor"
            onSubmit={handleSubmit(props.onSubmit)}
        >
            <div className="form-fields m-auto-0">
                <Controller
                    control={control}
                    name="emission_factor_table_id"
                    render={({ field: { ref, ...rest } }) => (
                        <SelectField
                            size="small"
                            {...rest}
                            sx={selectStyles}
                            id="emission_factor_table"
                            label="種別ID"
                            placeholder="種別IDを選択"
                            error={!!errors.emission_factor_table_id?.message ?? false}
                            isRequired
                            widthlabel={250}
                            menuItems={listFilters}
                            helperText={errors.emission_factor_table_id?.message}
                        />
                    )}
                />
                <div className="mt-20">
                    <Controller
                        control={control}
                        name="name"
                        render={({ field: { ref, ...rest } }) => (
                            <InputField
                                {...rest}
                                sx={inputStylyes}
                                widthlabel={250}
                                size="small"
                                id="name"
                                label="名称"
                                placeholder="名称を入力"
                                isRequired
                                error={!!errors.name?.message ?? false}
                                helperText={errors.name?.message}
                            />
                        )}
                    />
                </div>
                <div className="mt-20">
                    <Controller
                        control={control}
                        name="anti_warming"
                        render={({ field: { ref, ...rest } }) => {
                            const isDisabled = anti_warming !== 'antiWarming'
                            return (
                                <Box>
                                    <FormLabel className="fw-600 fz-14">温対法</FormLabel>
                                    <RadioGroup {...rest}>
                                        <FormControlLabel
                                            value="antiWarming"
                                            control={<Radio />}
                                            label="該当する"
                                            sx={{ ...radioStyles(isDisabled ? 300 : 600) }}
                                        />
                                        <Box className="ml-30">
                                            <Controller
                                                control={control}
                                                name="anti_warming_id"
                                                render={({ field: { ref, ...rest } }) => (
                                                    <SelectField
                                                        {...rest}
                                                        sx={selectStyles}
                                                        id="anti_warming_id"
                                                        label="該当算定項目"
                                                        size="small"
                                                        widthlabel={250}
                                                        isRequired
                                                        menuItems={listAntiWarmingTypes}
                                                        disabled={isDisabled}
                                                        className={isDisabled ? 'pointer-none' : ''}
                                                    />
                                                )}
                                            />
                                        </Box>
                                        <FormControlLabel
                                            value="antiWarmingNone"
                                            control={<Radio />}
                                            label="該当しない"
                                            sx={{ ...radioStyles(isDisabled ? 600 : 300) }}
                                        />
                                    </RadioGroup>
                                </Box>
                            )
                        }}
                    />
                </div>
                <div className="mt-20">
                    <Controller
                        control={control}
                        name="energy_saving"
                        render={({ field: { ref, ...rest } }) => {
                            const isEnergySavingActive = energy_saving === 'energySaving'
                            return (
                                <Box>
                                    <div>
                                        <FormLabel className="fw-600 fz-14">省エネ法</FormLabel>
                                        <CustomTooltip title="省エネ法での算出はScope1と2の対象エネルギーのみとなります。同じエネルギーでもScope3の活動量データは含まれません。">
                                            <IconButton>
                                                <FAQIcon />
                                            </IconButton>
                                        </CustomTooltip>
                                    </div>
                                    <RadioGroup {...rest}>
                                        <FormControlLabel
                                            value="energySaving"
                                            control={<Radio />}
                                            label="該当する"
                                            sx={radioStyles(isEnergySavingActive ? 600 : 300)}
                                        />
                                        <Box className="ml-30">
                                            <Controller
                                                control={control}
                                                name="energy_saving_id"
                                                render={({ field: { ref, ...rest } }) => (
                                                    <Box>
                                                        <FormLabel className="fw-600 fz-14">該当エネルギー</FormLabel>
                                                        <div
                                                            className={`bubble ${
                                                                energy_saving === 'energySaving' ? '' : 'pointer-none'
                                                            }`}
                                                        >
                                                            <RadioGroup {...rest}>
                                                                {Object.values(props.types.energySavingTypes).map(
                                                                    (child: EnergySavingScope[], childIndex) => {
                                                                        return (
                                                                            <Box
                                                                                key={childIndex}
                                                                                sx={{
                                                                                    padding: '40px 0',
                                                                                    '&:first-of-type': {
                                                                                        padding: '0 0 40px',
                                                                                    },
                                                                                    '&:last-of-type': {
                                                                                        padding: '40px 0 0',
                                                                                    },
                                                                                    '&:not(:last-of-type)': {
                                                                                        borderBottom:
                                                                                            '1px solid #BFBFBF',
                                                                                    },
                                                                                }}
                                                                            >
                                                                                {child.map((item, itemIndex) => {
                                                                                    return (
                                                                                        <Box
                                                                                            sx={{
                                                                                                '&:not(:first-of-type)':
                                                                                                    {
                                                                                                        marginTop:
                                                                                                            '20px',
                                                                                                    },
                                                                                            }}
                                                                                            key={itemIndex}
                                                                                        >
                                                                                            <FormLabel className="fz-14 fw-600">
                                                                                                {item.name}
                                                                                            </FormLabel>
                                                                                            {item.types && (
                                                                                                <Box
                                                                                                    sx={{
                                                                                                        display: 'grid',
                                                                                                        gridAutoFlow:
                                                                                                            'column',
                                                                                                        gridTemplateRows: `repeat(${Math.ceil(
                                                                                                            item.types
                                                                                                                .length /
                                                                                                                2,
                                                                                                        )}, 1fr)`,
                                                                                                        fontSize:
                                                                                                            '12px',
                                                                                                    }}
                                                                                                >
                                                                                                    {item.types.map(
                                                                                                        (t) => (
                                                                                                            <FormControlLabel
                                                                                                                key={
                                                                                                                    t.id
                                                                                                                }
                                                                                                                value={
                                                                                                                    t.id
                                                                                                                }
                                                                                                                control={
                                                                                                                    <Radio />
                                                                                                                }
                                                                                                                label={
                                                                                                                    t.name
                                                                                                                }
                                                                                                                sx={{
                                                                                                                    width: '300px',
                                                                                                                    '& .MuiTypography-root':
                                                                                                                        {
                                                                                                                            fontSize:
                                                                                                                                '12px',
                                                                                                                            fontWeight:
                                                                                                                                energy_saving_id ==
                                                                                                                                t.id
                                                                                                                                    ? 600
                                                                                                                                    : 300,
                                                                                                                        },
                                                                                                                    color: '#000000',
                                                                                                                }}
                                                                                                            />
                                                                                                        ),
                                                                                                    )}
                                                                                                </Box>
                                                                                            )}
                                                                                        </Box>
                                                                                    )
                                                                                })}
                                                                            </Box>
                                                                        )
                                                                    },
                                                                )}
                                                            </RadioGroup>
                                                        </div>
                                                    </Box>
                                                )}
                                            />
                                        </Box>
                                        <FormControlLabel
                                            value="energySavingNone"
                                            control={<Radio />}
                                            label="該当しない"
                                            sx={radioStyles(isEnergySavingActive ? 300 : 600)}
                                        />
                                    </RadioGroup>
                                </Box>
                            )
                        }}
                    />
                </div>
                <div className="mt-20">
                    <Controller
                        control={control}
                        name="value"
                        render={({ field: { ref, ...rest } }) => (
                            <InputField
                                {...rest}
                                sx={inputStylyes}
                                widthlabel={250}
                                type="number"
                                size="small"
                                id="value"
                                label="排出係数"
                                placeholder="排出係数を入力"
                                isRequired
                                error={!!errors.value?.message ?? false}
                                helperText={errors.value?.message}
                            />
                        )}
                    />
                </div>
                <div className="mt-20">
                    <Controller
                        control={control}
                        name="unit"
                        render={({ field: { ref, ...rest } }) => (
                            <InputField
                                {...rest}
                                sx={inputStylyes}
                                widthlabel={250}
                                size="small"
                                id="unit"
                                label="単位"
                                placeholder="単位を入力"
                                isRequired
                                error={!!errors.unit?.message ?? false}
                                helperText={errors.unit?.message}
                            />
                        )}
                    />
                </div>
                <div className="mt-20">
                    <Controller
                        control={control}
                        name="year"
                        render={({ field: { ref, ...rest } }) => (
                            <SelectField
                                {...rest}
                                sx={selectStyles}
                                id="year"
                                label="適用年"
                                placeholder="適用年を選択"
                                error={!!errors.year?.message ?? false}
                                size="small"
                                widthlabel={250}
                                isRequired
                                menuItems={YEAR_OPTIONS}
                                helperText={errors.year?.message}
                            />
                        )}
                    />
                </div>
            </div>
            <div className="mt-60 flex-center">
                <PrimaryButton
                    className="ef-form-btn fz-20"
                    variant="outlined"
                    onClick={() => navigate(routes.emissionFactorId)}
                >
                    キャンセル
                </PrimaryButton>
                <PrimaryButton className="ef-form-btn ml-40 fz-20" type="submit" disabled={disabledBtn}>
                    {props.isEdit ? '変更する' : '追加する'}
                </PrimaryButton>
            </div>
        </form>
    )
}

export default AddEditEmissionFactor
