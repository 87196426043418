import PrimaryButton from '@/components/atoms/Button/PrimaryButton'
import CustomModal from '@/components/atoms/CustomModal'
import InputField from '@/components/atoms/InputField'
import SelectableSiteTreeView from '@/components/organisms/SelectableSiteTree/SelectableSiteTreeView'
import { errors } from '@/constant/errors'
import { siteNodeApi, userApi } from '@/ghgApi'
import { Site, SiteNode, User } from '@/openapi'
import { companyStore } from '@/store/slices/companiesSlice'
import { setMessage } from '@/store/slices/messageSlice'
import theme from '@/theme'
import { makeStyles } from '@mui/styles'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { flattenNodes } from '@/utils/siteNodeHelper'
import { AppDispatch } from '@/store'

// 「全ての拠点」選択肢に便宜的に設定する拠点ID
export const ALL_SITES_OPTION_SITE_ID = -10

interface TSelectSitesModal {
    userId: string
    open: boolean
    onClose: (isAllClose: boolean) => void
}

const useStyle = makeStyles({
    container: {
        display: 'flex',
        gap: '8px',
        maxHeight: 600,
    },
    modalContent: {
        width: 560,
        margin: '25px auto',
    },
    btnGroup: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: 40,
        marginTop: 40,
    },
    showMore: {
        marginTop: 20,
        padding: '0 20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: 10,
        cursor: 'pointer',
        textDecoration: 'underline',
        '& > svg': {
            transform: 'scale(0.8)',
        },
        fontSize: 14,
        color: '#42526E',
    },
    btnResetLimit: {
        justifyContent: 'flex-end',
    },
    groupSelect: {
        padding: '0px 20px',
        marginTop: 25,
    },
    title: {
        fontSize: 14,
        color: '#000000',
    },
    listSelect: {
        display: 'flex',
        flexDirection: 'column',
    },
    item: {
        padding: '12px 10px',
        backgroundColor: '#517FA1',
        color: theme.colors.white,
        fontSize: 12,
        width: 'fit-content',
        minWidth: 200,
        height: 28,
        display: 'flex',
        alignItems: 'center',
        marginLeft: 10,
    },
    keywordForm: {
        width: '256px',
    },
})

export default function SelectSitesModal(props: TSelectSitesModal) {
    const { listUsers, organizationId } = useSelector(companyStore)
    const [user, setUser] = useState<User>()
    const [siteNodes, setSiteNodes] = useState<SiteNode[]>([])
    const [selectedSiteId, setSelectedSiteId] = useState<number[]>([])
    const [keyword, setKeyword] = useState<string>('')
    const dispatch = useDispatch<AppDispatch>()
    const classes = useStyle()

    useEffect(() => {
        if (!props.open) {
            return
        }
        if (!listUsers) {
            dispatch(setMessage({ message: 'ユーザ情報の取得に失敗しました。', type: 'error' }))
            return
        }
        const user = listUsers.find((user) => user.id == props.userId)
        if (!user) {
            dispatch(setMessage({ message: '対象のユーザが存在しません。', type: 'error' }))
            return
        }
        setUser(user)

        const userSiteIds = (user.userSites ?? []).map((site: Site) => site.id)
        setSelectedSiteId(userSiteIds.length > 0 ? userSiteIds : [ALL_SITES_OPTION_SITE_ID])
    }, [listUsers, props.open])

    useEffect(() => {
        if (!props.open) {
            return
        }
        fetchSiteNodes()
    }, [organizationId, props.open])

    const fetchSiteNodes = async () => {
        if (!organizationId) {
            return
        }
        try {
            const res = await siteNodeApi.getSiteNodes(organizationId)
            const { siteNodes } = res.data
            if (siteNodes) {
                setSiteNodes(siteNodes)
            }
        } catch (err) {
            console.warn(err)
        }
    }

    const getSiteName = (id: number) => {
        if (id === ALL_SITES_OPTION_SITE_ID) {
            return '全ての拠点'
        }
        return flattenNodes(siteNodes).find((n) => n.siteId == id)?.title
    }

    const handleClose = () => {
        props.onClose(false)
    }

    const handleClickSave = async () => {
        if (!user) {
            dispatch(setMessage({ message: '対象のユーザが存在しません。', type: 'error' }))
            return
        }
        try {
            await userApi.updateUser(
                user.id,
                { 
                    name: user.name,
                    email: user.email,
                    roleId: user.roleId,
                    userSites: selectedSiteId.some((siteId) => siteId === ALL_SITES_OPTION_SITE_ID) ? [] : selectedSiteId
                }
            )
            dispatch(setMessage({ message: 'ユーザーの更新に成功しました！', type: 'success' }))
        } catch (error) {
            dispatch(setMessage({ message: errors.ERROR_OCCURRED, type: 'error' }))
        }
        props.onClose(true)
    }

    const handleKeywordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(e.target.value)
    }

    const handleChangeSelected = (siteIds: number[]) => {
        setSelectedSiteId(siteIds)
    }

    return (
        <CustomModal
            title={'所属拠点の選択'}
            width={960}
            open={props.open}
            onClose={handleClose}
            aria-labelledby="search-modal-title"
            aria-describedby="search-modal-description"
        >
            <div className={`${classes.container} scroll-overlay`}>
                <div className={classes.modalContent}>
                    <div className={classes.title}>選択中の拠点</div>
                    {Array.isArray(selectedSiteId) && selectedSiteId && selectedSiteId.length > 0 ? (
                        <div className={classes.groupSelect}>
                            <div className={classes.listSelect}>
                                {selectedSiteId.map((siteId, index) => {
                                    return (
                                        <div
                                            key={index}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginTop: 12,
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <span style={{ minWidth: 16 }}>{index + 1}</span>
                                            <div className={classes.item} >
                                                <span style={{ marginLeft: 10 }}>{getSiteName(siteId)}</span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    ) : null}

                    <div className={classes.btnGroup}>
                        <PrimaryButton
                            className="ef-form-btn fz-20"
                            variant="outlined"
                            onClick={handleClose}
                        >
                            キャンセル
                        </PrimaryButton>
                        <PrimaryButton
                            onClick={handleClickSave}
                            style={{ width: '120px', height: '38px', fontSize: '16px', fontWeight: 'bold' }} 
                            disabled={selectedSiteId?.length === 0}
                        >
                            保存する
                        </PrimaryButton>
                    </div>
                </div>
                <div className={classes.modalContent}>
                    <div className={classes.keywordForm}>
                        <InputField
                            value={keyword}
                            placeholder="拠点名で検索"
                            onChange={handleKeywordChange}
                        />
                    </div>
                    <SelectableSiteTreeView
                        siteNodes={siteNodes}
                        selectedSiteIds={selectedSiteId}
                        keyword={keyword}
                        onChangeSelected={handleChangeSelected}
                    />
                </div>
            </div>
        </CustomModal>
    )
}
