import CustomModal from '@/components/atoms/CustomModal'
import { siteNodeApi } from '@/ghgApi'
import { makeStyles } from '@mui/styles'
import { SiteNode, SiteNodeSave } from '@/openapi'
import { AppDispatch } from '@/store'
import {
    companyStore,
    getSitesByOrganizationId,
} from '@/store/slices/companiesSlice'
import { setMessage } from '@/store/slices/messageSlice'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TModalCompany } from './ModalCreateCompany'
import InputField from '@/components/atoms/InputField'
import SiteTreeView from '../SiteTree/SiteTreeView'
import PrimaryButton from '@/components/atoms/Button/PrimaryButton'

const useStyles = makeStyles({
    itemTitle: {
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: '4px',
    },
    siteArea: {
        marginTop: '16px',
    },
    keywordForm: {
        width: '256px',
    },
    submitBtn: {
        marginTop: 50,
        textAlign: 'center',
    },
})

export default function ModalEditSite(props: TModalCompany) {
    const dispatch = useDispatch<AppDispatch>()
    const { organizationId, reUpdateModal } = useSelector(companyStore)
    // state pagination
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [keyword, setKeyword] = useState<string>('')
    const [siteNodes, setSiteNodes] = useState<SiteNode[]>([])
    const classes = useStyles()

    useEffect(() => {
        if (organizationId || organizationId === 0)
            dispatch(
                getSitesByOrganizationId({
                    id: organizationId,
                    options: {
                        search: searchTerm ? searchTerm : undefined,
                        offset: page * rowsPerPage,
                        limit: rowsPerPage,
                    },
                }),
            )
    }, [searchTerm, page, rowsPerPage, reUpdateModal])

    useEffect(() => {
        fetchSiteNodes()
    }, [])

    const fetchSiteNodes = async () => {
        if (!organizationId) {
            return
        }
        try {
            const res = await siteNodeApi.getSiteNodes(organizationId)
            const { siteNodes } = res.data
            if (siteNodes) {
                setSiteNodes(siteNodes)
            }
        } catch (err) {
            console.warn(err)
        }
    }

    const handleClose = () => {
        props.setOpen(false)
        setSearchTerm('')
        setPage(0)
        setRowsPerPage(10)
    }

    const handleKeywordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(e.target.value)
    }

    const handleSiteNodesChange = (siteNodes: SiteNode[]) => {
        setSiteNodes(siteNodes)
    }

    const handleSubmitClick = async () => {
        if (!organizationId) {
            console.log('organizationId is undefined.')
            return
        }
        try {
            await siteNodeApi.saveSiteNodes(
                organizationId,
                { siteNodes: convertToSiteNodeSave(organizationId, siteNodes) }
            )
            dispatch(setMessage({ message: '更新に成功しました！', type: 'success' }))
            props.setOpen(false)
        } catch (err) {
            dispatch(setMessage({ message: '更新に失敗しました！', type: 'error' }))
            console.warn(err)
        }
    }

    const convertToSiteNodeSave = (organizationId: number, siteNodes: SiteNode[]) => {
        const siteNodesSave: SiteNodeSave[] = siteNodes.filter((siteNode) => !siteNode.deleted).map((siteNode) => {
            return {
                siteId: siteNode.siteId && siteNode.siteId > 0 ? siteNode.siteId : undefined,
                organizationId: organizationId,
                parentSiteId: siteNode.parentSiteId,
                name: siteNode.title,
                children: convertToSiteNodeSave(organizationId, siteNode.children ?? []),
            }
        })
        return siteNodesSave
    }

    return (
        <CustomModal title="拠点一覧" open={props.open} onClose={handleClose} width={1600} >
            <>
                <div className={classes.siteArea}>
                    <div className={classes.itemTitle}>拠点名</div>
                    <div className={classes.keywordForm}>
                        <InputField
                            value={keyword}
                            placeholder="拠点名で検索"
                            onChange={handleKeywordChange}
                        />
                    </div>
                    <div>
                        <SiteTreeView
                            siteNodes={siteNodes}
                            keyword={keyword}
                            onChange={handleSiteNodesChange}
                        />
                    </div>
                </div>
                <div className={classes.submitBtn}>
                    <PrimaryButton style={{ width: '480px', height: '48px' }} onClick={handleSubmitClick}>
                        入力を保存する
                    </PrimaryButton>
                </div>
            </>
        </CustomModal>
    )
}
