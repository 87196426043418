import PlusCircleIcon from "@/components/atoms/Icon/PlusCircleIcon"
import SiteNodeView from "@/components/organisms/SiteTree/SiteNodeView"
import { SiteNode } from "@/openapi"
import { IconButton } from "@mui/material"
import { makeStyles } from '@mui/styles'
import React, { useCallback, useEffect, useRef, useState } from 'react'

const useStyles = makeStyles({
    addSiteButtonArea: {
        marginTop: '16px',
    },
    addSiteButton: {
        fontSize: '12px',
        color: '#FFFFFF',
        borderRadius: '4px',
        border: '1px solid #5B8AAD',
        backgroundColor: '#5B8AAD',
        fill: '#FFF',
        gap: '6px',
        '&:hover': {
            backgroundColor: '#3F6079!important',
        },
    },
    treeContainer: {
        padding: '24px',
    },
})

interface SiteTreeViewProps {
    siteNodes: SiteNode[]
    keyword?: string
    onChange: (siteNodes: SiteNode[]) => void
}

const SiteTreeView: React.FC<SiteTreeViewProps> = (props) => {
    const [siteNodes, setSiteNodes] = useState<SiteNode[]>(props.siteNodes)
    // DB未反映の追加ノードに一時的なSiteIdを付番
    const tmpSiteIdRef = useRef(-1)
    const classes = useStyles()

    useEffect(() => {
        setSiteNodes(props.siteNodes)
    }, [props.siteNodes])

    useEffect(() => {
        props.onChange(siteNodes)
    }, [siteNodes])

    const handleAddSiteNode = useCallback((parentSiteId?: number) => {
        const newSiteNode: SiteNode = { 
            siteId: tmpSiteIdRef.current--,
            parentSiteId: parentSiteId,
            title: '',
            isActivityEntered: false,
            deleted: false,
            children: [] ,
        }
        setSiteNodes(prevSiteNodes => {
            const updateChildren = (siteNodes: SiteNode[]): SiteNode[] => {
                if (!parentSiteId) {
                    return [...siteNodes, newSiteNode]
                } 
                return siteNodes.map(siteNode => {
                    if (siteNode.siteId === parentSiteId) {
                        return { ...siteNode, children: [...(siteNode.children || []), newSiteNode] }
                    } else if (siteNode.children) {
                        return { ...siteNode, children: updateChildren(siteNode.children) }
                    }
                    return siteNode
                })
            }
            return updateChildren(prevSiteNodes)
        })
    }, [])

    const handleUpdateSiteNode = useCallback((siteId: number, newName: string) => {
        setSiteNodes(prevSiteNodes => {
            const updateInChildren = (siteNodes: SiteNode[]): SiteNode[] => {
                return siteNodes.map(siteNode => {
                    if (siteNode.siteId === siteId) {
                        return { ...siteNode, title: newName }
                    } else if (siteNode.children) {
                        return { ...siteNode, children: updateInChildren(siteNode.children) }
                    }
                    return siteNode
                })
            }
            return updateInChildren(prevSiteNodes)
        })
    }, [])

    const handleDeleteSiteNodes = useCallback((siteId: number) => {
        setSiteNodes(prevSiteNodes => {
            const deleteFromChildren = (siteNodes: SiteNode[]): SiteNode[] => {
                return siteNodes.map(siteNode => {
                    if (siteNode.siteId == siteId) {
                        return {
                            ...siteNode,
                            deleted: true,
                        }
                    }
                    return siteNode
                }).map(siteNode => {
                    if (siteNode.children) {
                        return { ...siteNode, children: deleteFromChildren(siteNode.children) }
                    }
                    return siteNode
                })
            }
            return deleteFromChildren(prevSiteNodes)
        })
    }, [])

    const handleMoveSiteNode = useCallback((draggedSiteId: number, targetSiteId: number) => {
        setSiteNodes(prevSiteNodes => {
            let draggedSite: SiteNode | undefined
            const findAndRemove = (siteNodes: SiteNode[]): SiteNode[] => {
                return siteNodes.reduce((acc: SiteNode[], siteNode) => {
                    if (siteNode.siteId === draggedSiteId) {
                        draggedSite = JSON.parse(JSON.stringify(siteNode)) // Deep clone
                        return acc
                    }
                    const newSite = { ...siteNode }
                    if (newSite.children) {
                        newSite.children = findAndRemove(newSite.children)
                    }
                    return [...acc, newSite]
                }, [])
            }
            const addToTarget = (siteNode: SiteNode[]): SiteNode[] => {
                return siteNode.map(siteNode => {
                    if (siteNode.siteId === targetSiteId && draggedSite) {
                        return { ...siteNode, children: [...(siteNode.children || []), draggedSite] }
                    }
                    if (siteNode.children) {
                        return { ...siteNode, children: addToTarget(siteNode.children) }
                    }
                    return siteNode
                })
            }
            const newSites = findAndRemove([...prevSiteNodes])
            const result = addToTarget(newSites)
            return result
        })
    }, [])

    return (
        <>
            <div className={classes.addSiteButtonArea}>
                <IconButton
                    className={classes.addSiteButton}
                    onClick={() => handleAddSiteNode(undefined)}
                >
                    <PlusCircleIcon width={16} height={16} />第一階層に拠点を追加
                </IconButton>
            </div>
            <div className={classes.treeContainer}>
                {siteNodes.map((siteNode) => (
                    <SiteNodeView
                        key={siteNode.siteId}
                        siteNode={siteNode}
                        level={1}
                        keyword={props.keyword}
                        onAdd={handleAddSiteNode}
                        onUpdate={handleUpdateSiteNode}
                        onDelete={handleDeleteSiteNodes}
                        onMove={handleMoveSiteNode}
                    />
                ))}
            </div>
        </>
    )
}

export default SiteTreeView
