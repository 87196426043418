import { SiteNode } from "@/openapi"
import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useState } from 'react'
import SelectableSiteNodeView from "./SelectableSiteNodeView"

// 「全ての拠点」選択肢に便宜的に設定する拠点ID
export const ALL_SITES_OPTION_SITE_ID = -10

const useStyles = makeStyles((theme: Theme) => ({
    treeContainer: {
        padding: theme.spacing(3),
    },
}))

interface SelectableSiteTreeViewProps {
    siteNodes: SiteNode[]
    selectedSiteIds: number[]
    keyword?: string
    onChangeSelected: (siteIds: number[]) => void
}

const SelectableSiteTreeView: React.FC<SelectableSiteTreeViewProps> = (props) => {
    const [selectedSiteIds, setSelectedSiteIds] = useState<number[]>(props.selectedSiteIds)
    const classes = useStyles()

    const allSitesOption: SiteNode = {
        siteId: ALL_SITES_OPTION_SITE_ID,
        parentSiteId: undefined,
        title: '全ての拠点',
        isActivityEntered: false,
        children: []
    }
    const handleSelect = (siteId: number) => {
        const siteIds = siteId === ALL_SITES_OPTION_SITE_ID
            ? [siteId]
            : [...new Set([...selectedSiteIds, siteId].filter((id) => id !== ALL_SITES_OPTION_SITE_ID))]
        setSelectedSiteIds(siteIds)
        props.onChangeSelected(siteIds)
    }

    const handleDeSelect = (siteId: number) => {
        const siteIds = siteId === ALL_SITES_OPTION_SITE_ID
            ? []
            : selectedSiteIds.filter((id) => id !== siteId)
        setSelectedSiteIds(siteIds)
        props.onChangeSelected(siteIds)
    }

    return (
        <div className={classes.treeContainer}>
            <SelectableSiteNodeView
                key={'all-sites'}
                siteNode={allSitesOption}
                level={1}
                selectedSiteIds={selectedSiteIds}
                keyword={undefined}
                onSelect={handleSelect}
                onDeSelect={handleDeSelect}
            />
            {props.siteNodes.map((siteNode) => (
                <SelectableSiteNodeView
                    key={siteNode.siteId}
                    siteNode={siteNode}
                    level={1}
                    selectedSiteIds={selectedSiteIds}
                    keyword={props.keyword}
                    onSelect={handleSelect}
                    onDeSelect={handleDeSelect}
                />
            ))}
        </div>
    )
}

export default SelectableSiteTreeView
